/**
 * Enumeration des routes disponibles ici
 */
export enum RouteEnum {
	HOME = "/",
	LOGIN = '/login',
	denied = '/not-access',
	autoConnect = '/auto-connect/:data',
	requestForgotPassword = '/password-request',
	resetForgotPassword = '/password-reset/:token',
	accountActive = '/activation-account/:token',
	logout = '/logout',

	APP_HOME = "/accueil",

	// Update Password
	UPDATE_PASSWORD = "/update-password",
	// sire
	SITES_EDIT = "/dossiers/:dossierId/sites/edit/:id",
	SITES_ADD = "/dossiers/:dossierId/sites/create",
	SITES_CONSULTATION = "/dossiers/:dossierId/sites/consultation/:id",

	// Espaces
	ESPACES_LISTE = "/dossiers/:dossierId/sites/:id/espaces",
	ESPACES_CONSULTATION = "/dossiers/:dossierId/sites/:siteId/espaces/:id",
	ESPACES_EDIT = "/dossiers/:dossierId/sites/:siteId/espaces/edit/:id",
	ESPACES_ADD = "/dossiers/:dossierId/sites/:siteId/espaces/create",


	DOSSIERS_LISTE = "/dossiers",
	DOSSIERS_CONSULTATION = "/dossiers/consultation/:id",
	DOSSIERS_EDIT = "/dossiers/edit/:id",
	DOSSIERS_LIST_SITES = "/dossiers/edit/:id/sites",
	DOSSIERS_LIST_SITES_CONSULTATION = "/dossiers/consultation/:id/sites",
	DOSSIERS_ADD = "/dossiers/create",


	// Document
	DOCUMENTS = "/dossiers/:id/documents",

	ADMIN_UTILISATEURS_LIST = "/admin/utilisateurListPage",
	ADMIN_UTILISATEURS_EDIT = "/admin/utilisateur/edit/:id",
	ADMIN_UTILISATEURS_GESTION_ACCES = "/admin/utilisateur/edit/:id/access",
	ADMIN_UTILISATEURS_ADD = "/admin/utilisateurListPage/create",

	ADMIN_FABRIQUANTS_LIST = "/admin/fabriquantListPage",
	ADMIN_FABRIQUANTS_ADD = "/admin/fabriquantListPage/create",
	ADMIN_FABRIQUANTS_EDIT = "/admin/fabriquant/edit/:id",

	ADMIN_UTILISATEURSDOSSIER_ADD = "/admin/utilisateurDossier/create",


	ADMIN_STRUCTURE_CREATE = "/admin/structure/create",

	// Equipements
	LIST_EQUIPEMENT = "/dossiers/:dossierId/sites/:siteId/espaces/:id/equipement",
	EQUIPEMENT_EDIT = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/edit/:id",
	EQUIPEMENT_CREATE = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/create",

	//Contrôle principal
	LIST_CONTROLE = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:id",
	CONTROLE_EDIT = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controle/:id",
	CONTROLE_CREATE = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controle/create",

	//Contrôle Routine
	LIST_CONTROLE_ROUTINE = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:id",
	CONTROLE_EDIT_ROUTINE = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controleRoutine/:id",
	CONTROLE_CREATE_ROUTINE = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controleRoutine/create",

	//Contrôle Operationnel
	LIST_CONTROLE_OPERATIONNEL = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:id",
	CONTROLE_EDIT_OPERATIONNEL = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controleOperationnel/:id",
	CONTROLE_CREATE_OPERATIONNEL = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controleOperationnel/create",

	// Maintenance
	MAINTENANCE_ANNUEL_EDIT = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controleAnnuel/:CtrlID/maintenance/:id",
	MAINTENANCE_ANNUEL_CREATE = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controleAnnuel/:CtrlID/maintenance/create",

	MAINTENANCE_OPERATIONNEL_EDIT = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controleOperationnel/:CtrlID/maintenance/:id",
	MAINTENANCE_OPERATIONNEL_CREATE = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controleOperationnel/:CtrlID/maintenance/create",

	MAINTENANCE_ROUTINE_EDIT = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controleRoutine/:CtrlID/maintenance/:id",
	MAINTENANCE_ROUTINE_CREATE = "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controleRoutine/:CtrlID/maintenance/create",
}
